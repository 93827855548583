import { useState, useRef, useEffect } from "react";
import FeatherIcon from "./FeatherIcons";
import { formatDate } from "../utils/dateUtils";
import { useTheme } from "../components/ThemeProvider";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSignOutAlt,
    faGear,
    faUser,
    faChevronUp,
    faQuestionCircle,
    faRobot
} from "@fortawesome/free-solid-svg-icons";

const AppFooter = ({
    toggleSidebar,
    toggleCalendarVisibility,
    toggleSearchVisibility,
    selectedDate,
    hasUnsavedChanges,
    isLoading
}) => {
    const { theme } = useTheme();
    const navigate = useNavigate();
    const { user, logout } = useAuth();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);
    const toggleRef = useRef(null);

    const navbarClass = theme === "light" ? "bg-light" : theme === "dark" ? "bg-dark" : "bg-purple-900";
    const textClass = theme === "light" ? "text-dark" : "text-light";

    const navItems = [
        { name: "Settings", path: "/settings", icon: faGear },
        { name: "Account", path: "/account", icon: faUser },
        { name: "Support", path: "/support", icon: faQuestionCircle },
    ];

    const handleLogout = async () => {
        await logout();
        navigate("/login", { state: { fromLogout: true } });
    };

    const toggleDropdown = () => {
        if (!dropdownOpen) {
            setDropdownOpen(true);
            setTimeout(() => setDropdownVisible(true), 50);
        } else {
            setDropdownVisible(false);
            setTimeout(() => setDropdownOpen(false), 300);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                !toggleRef.current.contains(event.target)
            ) {
                setDropdownVisible(false);
                setTimeout(() => setDropdownOpen(false), 300);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const getCalendarButtonContent = () => {
        let iconColor, textColor;
        if (isLoading) {
            iconColor = textColor = "text-warning";
        } else if (!hasUnsavedChanges) {
            iconColor = textColor = "text-success";
        } else {
            iconColor = textColor = "text-secondary";
        }

        return (
            <>
                <FeatherIcon icon="calendar" width="15" height="15" className={iconColor} />
                <span className={`ms-2 ${textColor}`} style={{ fontSize: "0.8rem" }}>
                    {formatDate(selectedDate || new Date())}
                </span>
            </>
        );
    };

    return (
        <footer className="w-100 bg-primary-subtle">
            <div className="container-fluid d-flex justify-content-between align-items-center">
                {/* Calendar Controls */}
                <div className="d-flex align-items-center">
                    <button
                        className="btn btn-outline-secondary me-1 d-flex align-items-center border-0"
                        onClick={toggleSidebar}
                        style={{ height: "20px" }}
                        aria-label="Toggle Sidebar"
                    >
                        <FeatherIcon icon="sidebar" width="15" height="15" />
                    </button>
                    <button
                        className="btn d-flex align-items-center border-0 me-1"
                        onClick={toggleCalendarVisibility}
                        style={{ height: "20px" }}
                        aria-label="Toggle Calendar Visibility"
                    >
                        {getCalendarButtonContent()}
                    </button>
                    <button
                        className="btn btn-outline-secondary d-flex align-items-center border-0 me-1"
                        onClick={toggleSearchVisibility}
                        style={{ height: "20px" }}
                        aria-label="Toggle Search Visibility"
                    >
                        <FeatherIcon icon="search" width="14" height="14" />
                    </button>
                    <Link
                        to="/ai_chat"
                        className="btn btn-outline-secondary d-flex align-items-center border-0"
                        style={{ height: "20px" }}
                        aria-label="AI Chat"
                    >
                        <FontAwesomeIcon icon={faRobot} size="sm" className="me-1" />
                        <span style={{ fontSize: "0.8rem" }}>AI</span>
                    </Link>
                </div>

                {/* User Dropdown */}
                {user && (
                    <div className="navbar-nav">
                        <div className="nav-item dropdown">
                            <span
                                ref={toggleRef}
                                className={`nav-link ${textClass}`}
                                onClick={toggleDropdown}
                                style={{ cursor: "pointer" }}
                                aria-haspopup="true"
                                aria-expanded={dropdownOpen}
                            >
                                {user.username}
                                <FontAwesomeIcon
                                    icon={faChevronUp}
                                    className={`ms-2 transition-transform ${dropdownOpen ? 'rotate-180' : ''}`}
                                />
                            </span>
                            {dropdownOpen && (
                                <div
                                    ref={dropdownRef}
                                    className={`dropdown-menu ${dropdownVisible ? 'show' : ''}`}
                                    style={{
                                        position: 'absolute',
                                        top: 'auto',
                                        bottom: '100%',
                                        right: 0,
                                        left: 'auto',
                                        transform: `translateY(${dropdownVisible ? '0' : '10px'})`,
                                        opacity: dropdownVisible ? 1 : 0,
                                        transition: 'opacity 0.3s ease, transform 0.3s ease',
                                        display: 'block',
                                        minWidth: "200px",
                                        zIndex: 1000,
                                        boxShadow: "0 -4px 6px rgba(0, 0, 0, 0.1)",
                                        borderRadius: "4px",
                                        backgroundColor: theme === "light" ? "#fff" : "#343a40",
                                    }}
                                    aria-label="User Menu"
                                >
                                    {navItems.map((item) => (
                                        <Link
                                            key={item.name}
                                            className="dropdown-item"
                                            to={item.path}
                                            onClick={() => setDropdownOpen(false)}
                                        >
                                            <FontAwesomeIcon icon={item.icon} className="me-2" />
                                            {item.name}
                                        </Link>
                                    ))}
                                    <div className="dropdown-divider"></div>
                                    <button
                                        className="dropdown-item"
                                        onClick={handleLogout}
                                    >
                                        <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />
                                        Logout
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </footer>
    );
};

export default AppFooter;