import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Container, Form, Button, ListGroup, Alert, Modal } from 'react-bootstrap';
import NEW_JournalApiService from "../../services/NEW_JournalApiService";

const DataDbUserMgmt = () => {
    const [users, setUsers] = useState([]);
    const [newUsername, setNewUsername] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [newQuota, setNewQuota] = useState(209715200); // Default 200MB in bytes
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [userToDelete, setUserToDelete] = useState('');
    const [showQuotaModal, setShowQuotaModal] = useState(false);
    const [userToUpdateQuota, setUserToUpdateQuota] = useState('');
    const [newQuotaValue, setNewQuotaValue] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage] = useState(5);
    const { user } = useAuth();

    if (!user.is_admin) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                flexDirection: 'column'
            }}>
                <h1 style={{ fontSize: '4rem', marginBottom: '1rem' }}>403</h1>
                <h2>Forbidden</h2>
                <p>You do not have permission to access this page.</p>
            </div>
        );
    }

    useEffect(() => {
        if (user.is_admin) {
            fetchUsers();
        }
    }, [user]);

    const fetchUsers = async () => {
        setIsLoading(true);
        try {
            const data = await NEW_JournalApiService.listUsers(user.username);
            setUsers(data.users || []);
        } catch (err) {
            setError('Failed to fetch users');
        } finally {
            setIsLoading(false);
        }
    };

    const openDeleteModal = (username) => {
        setUserToDelete(username);
        setShowDeleteModal(true);
    };

    const closeDeleteModal = () => {
        setShowDeleteModal(false);
        setUserToDelete('');
    };

    const handleDeleteUser = async () => {
        try {
            await NEW_JournalApiService.deleteUser(userToDelete, user.username);
            setSuccess('User deleted successfully');
            fetchUsers();
            closeDeleteModal();
        } catch (err) {
            console.error('Error deleting user:', err);
            setError('Failed to delete user');
        }
    };

    const handleUpdateQuota = async () => {
        try {
            await NEW_JournalApiService.updateQuota(userToUpdateQuota, newQuotaValue, user.username);
            setSuccess(`Quota updated successfully for user ${userToUpdateQuota}`);
            fetchUsers();
            closeQuotaModal();
        } catch (err) {
            console.error('Error updating quota:', err);
            setError('Failed to update quota');
        }
    };

    const openQuotaModal = (username, currentQuota) => {
        setUserToUpdateQuota(username);
        setNewQuotaValue(currentQuota || 0);
        setShowQuotaModal(true);
    };

    const closeQuotaModal = () => {
        setShowQuotaModal(false);
        setUserToUpdateQuota('');
        setNewQuotaValue(0);
    };

    const formatBytes = (bytes) => {
        if (bytes === 0 || bytes === null) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };

    const filteredUsers = users.filter(user =>
        user.username.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <Container>
            <h2 className="my-4 bg-info-subtle">Data DB User Mgmt</h2>

            <h3>User List</h3>
            <Form.Group className="mb-3">
                <Form.Control
                    type="text"
                    placeholder="Search users..."
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                        setCurrentPage(1);
                    }}
                />
            </Form.Group>

            {isLoading ? (
                <p>Loading users...</p>
            ) : currentUsers.length > 0 ? (
                <ListGroup>
                    {currentUsers.map((user) => (
                        <ListGroup.Item key={user.username} className="d-flex justify-content-between align-items-center">
                            <div>
                                <strong>{user.username}</strong>
                                <br />
                                <small>Quota: {formatBytes(user.max_disk_usage)}</small>
                            </div>
                            <div>
                                <Button variant="primary" size="sm" className="me-2" onClick={() => openQuotaModal(user.username, user.max_disk_usage)}>
                                    Update Quota
                                </Button>
                                <Button variant="danger" size="sm" onClick={() => openDeleteModal(user.username)}>
                                    Delete
                                </Button>
                            </div>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            ) : (
                <p>No users found.</p>
            )}

            {filteredUsers.length > usersPerPage && (
                <Pagination
                    usersPerPage={usersPerPage}
                    totalUsers={filteredUsers.length}
                    paginate={paginate}
                    currentPage={currentPage}
                />
            )}

            {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
            {success && <Alert variant="success" className="mt-3">{success}</Alert>}

            <Modal show={showDeleteModal} onHide={closeDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete the user "{userToDelete}"?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDeleteModal}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteUser}>
                        Delete User
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showQuotaModal} onHide={closeQuotaModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Quota</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>New Quota for {userToUpdateQuota} (bytes)</Form.Label>
                        <Form.Control
                            type="number"
                            value={newQuotaValue}
                            onChange={(e) => setNewQuotaValue(parseInt(e.target.value))}
                            required
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeQuotaModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleUpdateQuota}>
                        Update Quota
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>


    );
};
const Pagination = ({ usersPerPage, totalUsers, paginate, currentPage }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalUsers / usersPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <nav>
            <ul className='pagination'>
                {pageNumbers.map(number => (
                    <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                        <a onClick={() => paginate(number)} href='#!' className='page-link'>
                            {number}
                        </a>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default DataDbUserMgmt;