// src/services/paymentApiService.js
import adminApiConfig from '../config/adminApiConfig';

const paymentApi = {

    getAllPayments: async (skip = 0, limit = 100) => {
        try {
            const response = await adminApiConfig.get(`/api/payments/admin`, {
                params: { skip, limit },
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching all payments:', error);
            throw error;
        }
    },

    makePayment: async (paymentData) => {
        try {
            const response = await adminApiConfig.post(`/api/payments/pay`, paymentData);
            return response.data;
        } catch (error) {
            console.error('Error making payment:', error);
            throw error;
        }
    },

    getUserPaymentHistory: async () => {
        try {
            const response = await adminApiConfig.get(`/api/payments/user/history`);
            return response.data;
        } catch (error) {
            console.error('Error fetching user payment history:', error);
            throw error;
        }
    },

};

export default paymentApi;