// adminApiConfig.jsx
import axios from 'axios';

const BASE_URL = import.meta.env.VITE_APP_BASE_URL;

const adminApiConfig = axios.create({
  baseURL: `${BASE_URL}`,
  withCredentials: true,
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// Define public routes that should not trigger token refresh
const publicRoutes = [
  '/api/auth/create-trial-user',
  '/api/auth/create-verification',
  '/api/auth/verify-email',
  '/plans',
];

adminApiConfig.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    // Do not process if the request is to a public route
    if (publicRoutes.includes(originalRequest.url)) {
      return Promise.reject(error);
    }

    // Check if the error is a 401 and the request hasn't been retried yet
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      if (originalRequest.url === '/api/auth/refresh') {
        // If the refresh request itself fails, do not retry
        return Promise.reject(error);
      }

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        }).then(token => {
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          return adminApiConfig(originalRequest);
        }).catch(err => {
          return Promise.reject(err);
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const response = await adminApiConfig.post('/api/auth/refresh');
        const { access_token } = response.data;

        adminApiConfig.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
        originalRequest.headers['Authorization'] = 'Bearer ' + access_token;

        processQueue(null, access_token);
        return adminApiConfig(originalRequest);
      } catch (refreshError) {
        processQueue(refreshError, null);
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

export default adminApiConfig;

