import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Container, Row, Col, Card, Button, Table, Alert, Spinner } from 'react-bootstrap';
import NEW_JournalApiService from "../../services/NEW_JournalApiService";

const DiskUsageMonitor = () => {
    const [diskUsage, setDiskUsage] = useState([]);
    const [latestCheck, setLatestCheck] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const { user } = useAuth();

    if (!user.is_admin) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                flexDirection: 'column'
            }}>
                <h1 style={{ fontSize: '4rem', marginBottom: '1rem' }}>403</h1>
                <h2>Forbidden</h2>
                <p>You do not have permission to access this page.</p>
            </div>
        );
    }

    useEffect(() => {
        if (user.is_admin) {
            fetchLatestCheck();
        }
    }, [user]);

    const fetchDiskUsage = async () => {
        setIsLoading(true);
        setError('');
        try {
            const data = await NEW_JournalApiService.checkDiskUsage(null, user.username);
            setDiskUsage(data.results);
            setSuccess('Disk usage check completed successfully.');
        } catch (err) {
            setError('Failed to fetch disk usage information.');
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    const triggerBackgroundCheck = async () => {
        setIsLoading(true);
        setError('');
        try {
            await NEW_JournalApiService.triggerBackgroundCheck(user.username);
            setSuccess('Background disk usage check triggered successfully.');
            setTimeout(fetchLatestCheck, 5000); // Fetch latest check after 5 seconds
        } catch (err) {
            setError('Failed to trigger background disk usage check.');
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchLatestCheck = async () => {
        setIsLoading(true);
        setError('');
        try {
            const data = await NEW_JournalApiService.getLatestDiskCheck(user.username);
            setLatestCheck(data.latest_check);
        } catch (err) {
            setError('Failed to fetch latest disk check information.');
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    const formatBytes = (bytes) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleString();
    };

    return (
        <Container className="mt-4">
            <h2 className="mb-4">Disk Usage Monitor</h2>

            <Row className="mb-4">
                <Col md={6}>
                    <Card>
                        <Card.Header>Actions</Card.Header>
                        <Card.Body>
                            <Button
                                variant="primary"
                                onClick={fetchDiskUsage}
                                disabled={isLoading}
                                className="me-2 mb-2"
                            >
                                Check Disk Usage Now
                            </Button>
                            <Button
                                variant="secondary"
                                onClick={triggerBackgroundCheck}
                                disabled={isLoading}
                                className="mb-2"
                            >
                                Trigger Background Check
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card>
                        <Card.Header>Latest Check Information</Card.Header>
                        <Card.Body>
                            {latestCheck ? (
                                <>
                                    <p><strong>Start Time:</strong> {formatDate(latestCheck.start_time)}</p>
                                    <p><strong>End Time:</strong> {formatDate(latestCheck.end_time)}</p>
                                    <p><strong>Duration:</strong> {latestCheck.duration.toFixed(2)} seconds</p>
                                </>
                            ) : (
                                <p>No recent checks found.</p>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {isLoading && <Spinner animation="border" role="status" className="mb-4" />}

            {error && <Alert variant="danger" className="mb-4">{error}</Alert>}
            {success && <Alert variant="success" className="mb-4">{success}</Alert>}

            {diskUsage.length > 0 && (
                <Card>
                    <Card.Header>Current Disk Usage</Card.Header>
                    <Card.Body>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Username</th>
                                    <th>Current Usage</th>
                                    <th>Quota</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {diskUsage.map((user) => (
                                    <tr key={user.username}>
                                        <td>{user.username}</td>
                                        <td>{formatBytes(user.current_usage)}</td>
                                        <td>{formatBytes(user.max_usage)}</td>
                                        <td>
                                            <span className={`badge ${user.is_exceeded ? 'bg-danger' : 'bg-success'}`}>
                                                {user.is_exceeded ? 'Exceeded' : 'Within Limit'}
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            )}
        </Container>
    );
};

export default DiskUsageMonitor;