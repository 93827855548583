// JournalApiConfig.jsx
import axios from 'axios';

const BASE_URL = import.meta.env.VITE_APP_BASE_URL;

const journalApi = axios.create({
  baseURL: `${BASE_URL}/journaldata`,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

journalApi.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    // Check if the request is a logout request
    if (originalRequest.url === '/auth/logout') {
      return Promise.reject(error);
    }

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      if (originalRequest.url === '/api/auth/refresh') {
        // If the refresh request itself fails, don't retry
        return Promise.reject(error);
      }

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        }).then(token => {
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          return journalApi(originalRequest);
        }).catch(err => {
          return Promise.reject(err);
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        // Use axios directly for the refresh call
        const response = await axios.post(`${BASE_URL}/api/auth/refresh`, {}, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        });

        const { access_token } = response.data;

        if (access_token) {
          journalApi.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
          originalRequest.headers['Authorization'] = 'Bearer ' + access_token;

          processQueue(null, access_token);
          return journalApi(originalRequest);
        } else {
          throw new Error('No access token received');
        }
      } catch (refreshError) {
        processQueue(refreshError, null);
        // Clear any stored tokens or trigger a logout here
        // For example: localStorage.removeItem('user');
        // You might want to redirect to a login page here
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

export default journalApi;