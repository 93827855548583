import { useEffect, useRef } from "react";
import feather from "feather-icons";

const FeatherIcon = ({ icon, ...props }) => {
  const iconRef = useRef(null);

  useEffect(() => {
    if (iconRef.current) {
      feather.replace({ element: iconRef.current });
    }
  }, [icon]);

  return <i ref={iconRef} data-feather={icon} {...props}></i>;
};

export default FeatherIcon;
