import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Table, Button, Modal, Form, Pagination, Alert, Spinner, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import subscriptionApiService from '../../services/subscriptionApiService';

const SubscriptionManagement = () => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sortField, setSortField] = useState('id');
    const [sortOrder, setSortOrder] = useState('asc');
    const [modalVisible, setModalVisible] = useState(false);
    const [modalMode, setModalMode] = useState('create');
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const [formData, setFormData] = useState({});
    const [error, setError] = useState(null);
    const { user } = useAuth();

    useEffect(() => {
        if (user.is_admin) {
            fetchSubscriptions();
        }
    }, [page, pageSize, sortField, sortOrder, user]);

    const fetchSubscriptions = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await subscriptionApiService.getAllSubscriptions({
                skip: (page - 1) * pageSize,
                limit: pageSize,
                sort_field: sortField,
                sort_order: sortOrder
            });
            setSubscriptions(response.items);
            setTotal(response.total);
        } catch (error) {
            console.error('Error fetching subscriptions:', error);
            setError('Failed to fetch subscriptions. Please try again later.');
            setSubscriptions([]);
            setTotal(0);
        } finally {
            setLoading(false);
        }
    };

    const handleSort = (field) => {
        const isAsc = sortField === field && sortOrder === 'asc';
        setSortField(field);
        setSortOrder(isAsc ? 'desc' : 'asc');
    };

    const showModal = (mode, subscription = null) => {
        setModalMode(mode);
        setSelectedSubscription(subscription);
        setFormData(subscription ? {
            ...subscription,
            start_date: formatDateForInput(subscription.start_date),
            end_date: formatDateForInput(subscription.end_date),
            trial_end_date: formatDateForInput(subscription.trial_end_date),
            is_trial: subscription.is_trial || false,
            auto_renew: subscription.auto_renew || false,
            user_id: subscription.user?.id || subscription.user_id,
            plan_id: subscription.plan?.id || subscription.plan_id,
        } : {
            start_date: formatDateForInput(new Date().toISOString()),
            end_date: '',
            trial_end_date: '',
            is_trial: false,
            auto_renew: true,
        });
        setModalVisible(true);
    };

    const handleModalSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError(null);
        try {
            const submissionData = {
                plan_id: parseInt(formData.plan_id),
                status: formData.status,
                start_date: formatDateForSubmission(formData.start_date),
                end_date: formatDateForSubmission(formData.end_date),
                auto_renew: formData.auto_renew,
                is_trial: formData.is_trial,
                trial_end_date: formatDateForSubmission(formData.trial_end_date),
            };
            if (modalMode === 'create') {
                await subscriptionApiService.createSubscriptionForUser(parseInt(formData.user_id), submissionData);
            } else if (modalMode === 'update') {
                await subscriptionApiService.updateSubscription(selectedSubscription.id, submissionData);
            }
            setModalVisible(false);
            fetchSubscriptions();
        } catch (error) {
            console.error('Error processing subscription:', error);
            setError('Failed to process subscription. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = async (subscriptionId) => {
        setLoading(true);
        setError(null);
        try {
            await subscriptionApiService.cancelSubscription(subscriptionId);
            fetchSubscriptions();
        } catch (error) {
            console.error('Error cancelling subscription:', error);
            setError('Failed to cancel subscription. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const formatDateForDisplay = (isoString) => {
        return isoString ? new Date(isoString).toLocaleDateString() : 'N/A';
    };

    const formatDateForInput = (isoString) => {
        return isoString ? isoString.split('T')[0] : '';
    };

    const formatDateForSubmission = (dateString) => {
        return dateString ? `${dateString}T00:00:00Z` : null;
    };

    const columns = [
        { field: 'id', label: 'ID' },
        { field: 'user_id', label: 'User ID' },
        { field: 'plan_id', label: 'Plan ID' },
        { field: 'status', label: 'Status' },
        { field: 'start_date', label: 'Start Date' },
        { field: 'end_date', label: 'End Date' },
        { field: 'auto_renew', label: 'Auto Renew' },
        { field: 'is_trial', label: 'Trial' },
        { field: 'trial_end_date', label: 'Trial End' },
        { field: 'payments', label: 'Payments' },
    ];

    const renderPaymentsBadge = (payments) => {
        const completedPayments = payments?.filter(p => p.status === 'completed') || [];
        return (
            <Badge bg={completedPayments.length > 0 ? 'success' : 'secondary'}>
                {completedPayments.length}
            </Badge>
        );
    };

    if (loading && subscriptions.length === 0) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
        );
    }

    return (
        <div className="container mt-4">
            <Button variant="primary" onClick={() => showModal('create')} className="mb-3">
                Create Subscription
            </Button>

            {error && <Alert variant="danger">{error}</Alert>}

            {subscriptions.length === 0 ? (
                <Alert variant="info">No subscriptions found.</Alert>
            ) : (
                <>
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                {columns.map(({ field, label }) => (
                                    <th key={field} onClick={() => handleSort(field)} style={{ cursor: 'pointer' }}>
                                        {label}
                                        {sortField === field && (
                                            <FontAwesomeIcon
                                                icon={sortOrder === 'asc' ? faSortUp : faSortDown}
                                                className="ml-2"
                                            />
                                        )}
                                    </th>
                                ))}
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {subscriptions.map((subscription) => (
                                <tr key={subscription.id}>
                                    {columns.map(({ field }) => (
                                        <td key={field}>
                                            {field === 'payments' ? renderPaymentsBadge(subscription.payments) :
                                                field === 'start_date' || field === 'end_date' || field === 'trial_end_date'
                                                    ? formatDateForDisplay(subscription[field])
                                                    : field === 'auto_renew' || field === 'is_trial'
                                                        ? subscription[field] ? 'Yes' : 'No'
                                                        : field === 'status'
                                                            ? <Badge bg={subscription.status === 'active' ? 'success' : 'warning'}>
                                                                {subscription.status}
                                                            </Badge>
                                                            : subscription[field] || 'N/A'}
                                        </td>
                                    ))}
                                    <td>
                                        <Button variant="info" size="sm" onClick={() => showModal('view', subscription)} className="me-1">
                                            View
                                        </Button>
                                        <Button variant="warning" size="sm" onClick={() => showModal('update', subscription)} className="me-1">
                                            Update
                                        </Button>
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            onClick={() => handleCancel(subscription.id)}
                                            disabled={subscription.status === 'cancelled'}
                                        >
                                            Cancel
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    <Pagination>
                        <Pagination.Prev
                            onClick={() => setPage(prev => Math.max(1, prev - 1))}
                            disabled={page === 1}
                        />
                        <Pagination.Item>{page}</Pagination.Item>
                        <Pagination.Next
                            onClick={() => setPage(prev => Math.min(Math.ceil(total / pageSize), prev + 1))}
                            disabled={page >= Math.ceil(total / pageSize)}
                        />
                    </Pagination>
                </>
            )}

            <Modal show={modalVisible} onHide={() => setModalVisible(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {modalMode === 'create' ? 'Create Subscription' : modalMode === 'update' ? 'Update Subscription' : 'View Subscription'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleModalSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>User ID</Form.Label>
                            <Form.Control
                                type="number"
                                name="user_id"
                                value={formData.user_id || ''}
                                onChange={handleInputChange}
                                disabled={modalMode !== 'create'}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Plan ID</Form.Label>
                            <Form.Control
                                type="number"
                                name="plan_id"
                                value={formData.plan_id || ''}
                                onChange={handleInputChange}
                                disabled={modalMode === 'view'}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Status</Form.Label>
                            <Form.Control
                                as="select"
                                name="status"
                                value={formData.status || ''}
                                onChange={handleInputChange}
                                disabled={modalMode === 'view' || modalMode === 'create'}
                                required
                            >
                                <option value="active">Active</option>
                                <option value="cancelled">Cancelled</option>
                                <option value="expired">Expired</option>
                                <option value="trial">Trial</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control
                                type="date"
                                name="start_date"
                                value={formData.start_date || ''}
                                onChange={handleInputChange}
                                disabled={modalMode === 'view'}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>End Date</Form.Label>
                            <Form.Control
                                type="date"
                                name="end_date"
                                value={formData.end_date || ''}
                                onChange={handleInputChange}
                                disabled={modalMode === 'view'}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Check
                                type="checkbox"
                                label="Auto Renew"
                                name="auto_renew"
                                checked={formData.auto_renew || false}
                                onChange={handleInputChange}
                                disabled={modalMode === 'view'}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Check
                                type="checkbox"
                                label="Is Trial"
                                name="is_trial"
                                checked={formData.is_trial || false}
                                onChange={handleInputChange}
                                disabled={modalMode === 'view'}
                            />
                        </Form.Group>
                        {formData.is_trial && (
                            <Form.Group className="mb-3">
                                <Form.Label>Trial End Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="trial_end_date"
                                    value={formData.trial_end_date || ''}
                                    onChange={handleInputChange}
                                    disabled={modalMode === 'view'}
                                />
                            </Form.Group>
                        )}

                        {modalMode === 'view' && subscription?.payments?.length > 0 && (
                            <div className="mt-4">
                                <h5>Payment History</h5>
                                <Table size="sm">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {subscription.payments.map((payment) => (
                                            <tr key={payment.id}>
                                                <td>{formatDateForDisplay(payment.payment_date)}</td>
                                                <td>${payment.amount}</td>
                                                <td>
                                                    <Badge bg={payment.status === 'completed' ? 'success' : 'warning'}>
                                                        {payment.status}
                                                    </Badge>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        )}

                        {modalMode !== 'view' && (
                            <div className="mt-4">
                                <Button variant="secondary" onClick={() => setModalVisible(false)} className="me-2">
                                    Cancel
                                </Button>
                                <Button variant="primary" type="submit">
                                    {modalMode === 'create' ? 'Create' : 'Update'}
                                </Button>
                            </div>
                        )}
                    </Form>

                    {modalMode === 'view' && selectedSubscription?.paypal_events?.length > 0 && (
                        <div className="mt-4">
                            <h5>PayPal Events</h5>
                            <Table size="sm">
                                <thead>
                                    <tr>
                                        <th>Event Type</th>
                                        <th>Date</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedSubscription.paypal_events.map((event) => (
                                        <tr key={event.event_id}>
                                            <td>{event.event_type}</td>
                                            <td>{formatDateForDisplay(event.event_time)}</td>
                                            <td>
                                                <Badge bg={event.processed ? 'success' : 'warning'}>
                                                    {event.processed ? 'Processed' : 'Pending'}
                                                </Badge>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default SubscriptionManagement;