// src/services/subscriptionApiService.js
import adminApiConfig from '../config/adminApiConfig';

const subscriptionApiService = {

    // Fetch all subscription plans
    async getSubscriptionPlans() {
        const response = await adminApiConfig.get('/api/plans/');
        return response.data;
    },

    // Create a new subscription plan
    async createSubscriptionPlan(planData) {
        const response = await adminApiConfig.post('/api/plans/', planData);
        return response.data;
    },

    // Update an existing subscription plan
    async updateSubscriptionPlan(planId, planData) {
        const response = await adminApiConfig.put(`/api/plans/${planId}`, planData);
        return response.data;
    },

    // Delete a subscription plan
    async deleteSubscriptionPlan(planId) {
        const response = await adminApiConfig.delete(`/api/plans/${planId}`);
        return response.data;
    },

    // Set the price for a subscription plan
    async setSubscriptionPlanPrice(planId, priceData) {
        const response = await adminApiConfig.post(`/api/plans/${planId}/price`, priceData);
        return response.data;
    },
    // Create a new subscription
    async createSubscription(subscriptionData) {
        try {
            const response = await adminApiConfig.post('/api/subscriptions/', subscriptionData);
            return response.data;
        } catch (error) {
            console.error('Error creating subscription:', error);
            if (error.response) {
                console.error('Response data:', error.response.data);
                console.error('Response status:', error.response.status);
                console.error('Response headers:', error.response.headers);
            }
            throw error;
        }
    },
    /**
         * Get the current user's subscription details for the purpose of user data DB. 
         * @returns {Promise<Object>} The user's current subscription details
         */
    getCurrentUserSubscription: async () => {
        try {
            const response = await adminApiConfig.get('/api/subscriptions/user/current');
            return response.data;
        } catch (error) {
            console.error('Error fetching current user subscription:', error);
            if (error.response) {
                console.error('Response data:', error.response.data);
                console.error('Response status:', error.response.status);
                console.error('Response headers:', error.response.headers);
            }
            throw error;
        }
    },
    // Fetch all subscriptions (for admin purposes)
    async getAllSubscriptions({ skip = 0, limit = 10, sort_field = 'id', sort_order = 'asc' }) {
        try {
            const response = await adminApiConfig.get('/api/admin/subscriptions', {
                params: { skip, limit, sort_field, sort_order }
            });
            return {
                items: response.data.items,
                total: response.data.total,
                skip: response.data.skip,
                limit: response.data.limit
            };
        } catch (error) {
            console.error('Error fetching all subscriptions:', error);
            throw error;
        }
    },
    // Change subscription plan
    async changeSubscriptionPlan(paypalPlanId, taskplexPlanId) {
        // Ensure the payload matches PayPalSubscriptionChangeRequest model
        const payload = {
            new_paypal_plan_id: paypalPlanId,         // string
            new_taskplex_plan_id: parseInt(taskplexPlanId)  // integer
        };

        console.log('Sending plan change request with payload:', payload);

        try {
            const response = await adminApiConfig.post('/api/paypal/change-plan', payload);
            return response.data;
        } catch (error) {
            console.error('Error changing subscription plan:', {
                status: error.response?.status,
                data: error.response?.data,
                headers: error.response?.headers
            });
            throw error;
        }
    },
    // ==============================================
    // Cancel subscription
    async cancelSubscription(reason = "", cancelAll = false) {
        try {
            const response = await adminApiConfig.post('/api/paypal/cancel', null, {
                params: { reason, cancel_all: cancelAll }
            });
            return response.data;
        } catch (error) {
            console.error('Error cancelling subscription:', error);
            if (error.response?.data?.detail === "No active PayPal subscription found") {
                throw new Error("No active subscription found to cancel.");
            }
            throw error;
        }
    },

    // Cancel storage subscription only
    async cancelStorageSubscription(reason = "") {
        return this.cancelSubscription(reason, false);
    },

    // Cancel all subscriptions
    async cancelAllSubscriptions(reason = "") {
        return this.cancelSubscription(reason, true);
    },
    // ==============================================
    // Check status of subscription changes
    async checkStatusChange(subscriptionId) {
        const response = await adminApiConfig.get(`/api/paypal/status-change/${subscriptionId}`);  // Also add 's' here
        return response.data;
    },
    cancelTrialSubscription: async () => {
        try {
            console.log('Attempting to cancel trial subscription...');
            const response = await adminApiConfig.put('/api/subscriptions/cancel-trial');
            console.log('Cancel trial subscription response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Subscription cancellation error details:', {
                status: error.response?.status,
                data: error.response?.data,
                message: error.message
            });

            if (error.response) {
                if (error.response.status === 429) {
                    throw new Error('Rate limit exceeded. Please try again later.');
                } else if (error.response.status === 401) {
                    throw new Error('Authentication required. Please log in again.');
                } else if (error.response.status === 500) {
                    // Include the server error message if available
                    const serverMessage = error.response.data?.detail || 'Server error while canceling subscription. Please try again later.';
                    throw new Error(serverMessage);
                }
                throw error;
            } else if (error.request) {
                throw new Error('No response received from server while canceling subscription');
            } else {
                throw new Error('Error setting up the subscription cancellation request');
            }
        }
    },
    /**
     * Get PayPal plan details for a given plan ID
     * @param {number} planId - The TaskPlex plan ID
     * @returns {Promise<Object>} The PayPal plan and product IDs
     */
    async getPayPalPlanDetails(planId) {
        try {
            // Use path parameter instead of query parameter
            const response = await adminApiConfig.get(`/api/subscriptions/paypal_product_plan_id/${planId}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching PayPal plan details:', error);
            if (error.response?.status === 404) {
                throw new Error("Subscription plan not found.");
            }
            if (error.response?.status === 403) {
                throw new Error("You don't have permission to access this plan information.");
            }
            if (error.response?.status === 422) {
                throw new Error("Invalid plan ID format.");
            }
            throw error;
        }
    },
    // Change storage subscription plan
    async changeStorageSubscriptionPlan(paypalPlanId, taskplexPlanId) {
        const payload = {
            new_paypal_plan_id: paypalPlanId,
            new_taskplex_plan_id: parseInt(taskplexPlanId)
        };

        try {
            const response = await adminApiConfig.post('/api/paypal/change-storage-plan', payload);
            return response.data;
        } catch (error) {
            console.error('Error changing storage subscription plan:', {
                status: error.response?.status,
                data: error.response?.data,
                headers: error.response?.headers
            });

            // Handle specific error cases
            if (error.response?.status === 404) {
                throw new Error("No active storage subscription found.");
            }
            if (error.response?.status === 400 && error.response.data?.detail === "Invalid storage plan selected") {
                throw new Error("Invalid storage plan selected.");
            }
            throw error;
        }
    },
    /**
     * Fetch the base storage amount in GB for a specific subscription plan
     * @param {number} planId - The TaskPlex plan ID to get base storage for
     * @returns {Promise<number>} The base storage amount in GB
     * @throws {Error} When the plan is not found or other API errors occur
     */
    async getPlanBaseStorage(planId) {
        try {
            const response = await adminApiConfig.get(`/api/storage/subscription-plan/storage/${planId}`);
            return response.data; // Returns the base storage value in GB as a float
        } catch (error) {
            console.error('Error fetching plan base storage:', error);

            // Handle specific error cases
            if (error.response?.status === 404) {
                throw new Error("Subscription plan not found.");
            }
            if (error.response?.status === 401) {
                throw new Error("Authentication required. Please log in.");
            }
            if (error.response?.status === 422) {
                throw new Error("Invalid plan ID format.");
            }

            // Handle unexpected errors
            throw new Error(
                error.response?.data?.detail ||
                'Failed to fetch plan base storage. Please try again later.'
            );
        }
    },

};

export default subscriptionApiService; 