import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Form, Button, Container, Table, Spinner, Row, Col } from 'react-bootstrap';
import subscriptionApiService from '../../services/subscriptionApiService';

const SubscriptionPlanManager = () => {
    const [plans, setPlans] = useState([]);
    const [newPlan, setNewPlan] = useState({
        name: '',
        description: '',
        billing_cycle: 'monthly',
        base_storage_gb: 0,
        trial_period_days: 14,
        current_price: 0,
        features: '',
        is_trial_only: false,
        paypal_plan_id: '',
        paypal_product_id: ''
    });
    const [selectedPlanId, setSelectedPlanId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const { user } = useAuth();

    if (!user.is_admin) {
        return (
            <div className="flex justify-center items-center h-screen flex-col">
                <h1 className="text-6xl mb-4">403</h1>
                <h2>Forbidden</h2>
                <p>You do not have permission to access this page.</p>
            </div>
        );
    }

    const fetchSubscriptionPlans = useCallback(async () => {
        try {
            setLoading(true);
            const data = await subscriptionApiService.getSubscriptionPlans();
            setPlans(data);
        } catch (error) {
            console.error('Error fetching subscription plans:', error);
            setPlans([]);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (user.is_admin) {
            fetchSubscriptionPlans();
        }
    }, [fetchSubscriptionPlans, user]);

    useEffect(() => {
        if (selectedPlanId) {
            const selectedPlan = plans.find((plan) => plan.id === selectedPlanId);
            if (selectedPlan) {
                setNewPlan({
                    name: selectedPlan.name ?? '',
                    description: selectedPlan.description ?? '',
                    billing_cycle: selectedPlan.billing_cycle ?? 'monthly',
                    base_storage_gb: selectedPlan.base_storage_gb ?? 0,
                    trial_period_days: selectedPlan.trial_period_days ?? 14,
                    current_price: selectedPlan.current_price ?? 0,
                    features: selectedPlan.features ?? '',
                    is_trial_only: selectedPlan.is_trial_only ?? false,
                    paypal_plan_id: selectedPlan.paypal_plan_id ?? '',
                    paypal_product_id: selectedPlan.paypal_product_id ?? ''
                });
            }
        } else {
            resetForm();
        }
    }, [selectedPlanId, plans]);

    const resetForm = () => {
        setNewPlan({
            name: '',
            description: '',
            billing_cycle: 'monthly',
            base_storage_gb: 0,
            trial_period_days: 14,
            current_price: 0,
            features: '',
            is_trial_only: false,
            paypal_plan_id: '',
            paypal_product_id: ''
        });
        setSelectedPlanId(null);
    };

    const handleCreatePlan = async () => {
        try {
            await subscriptionApiService.createSubscriptionPlan(newPlan);
            await fetchSubscriptionPlans();
            resetForm();
        } catch (error) {
            console.error('Error creating subscription plan:', error);
        }
    };

    const handleUpdatePlan = async () => {
        if (!selectedPlanId) return;
        try {
            await subscriptionApiService.updateSubscriptionPlan(selectedPlanId, newPlan);
            await fetchSubscriptionPlans();
            resetForm();
        } catch (error) {
            console.error('Error updating subscription plan:', error);
        }
    };

    const handleDeletePlan = async (planId) => {
        try {
            await subscriptionApiService.deleteSubscriptionPlan(planId);
            await fetchSubscriptionPlans();
        } catch (error) {
            console.error('Error deleting subscription plan:', error);
        }
    };

    const sortedPlans = React.useMemo(() => {
        let sortablePlans = [...plans];
        if (sortConfig.key !== null) {
            sortablePlans.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortablePlans;
    }, [plans, sortConfig]);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const getSortDirection = (name) => {
        if (!sortConfig.key) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
        <Container className="mt-5 mb-5">
            <h1>Subscription Plan Management</h1>

            <Row className="mb-4">
                <Col md={8}>
                    <h2>{selectedPlanId ? 'Update' : 'Create'} Subscription Plan</h2>
                    <Form>
                        {/* Existing form fields remain the same */}
                        <Form.Group className="mb-3">
                            <Form.Label>Plan Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter plan name"
                                value={newPlan.name}
                                onChange={(e) => setNewPlan({ ...newPlan, name: e.target.value })}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Enter description"
                                value={newPlan.description}
                                onChange={(e) => setNewPlan({ ...newPlan, description: e.target.value })}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Features</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Enter features"
                                value={newPlan.features}
                                onChange={(e) => setNewPlan({ ...newPlan, features: e.target.value })}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Billing Cycle</Form.Label>
                            <Form.Select
                                value={newPlan.billing_cycle}
                                onChange={(e) => setNewPlan({ ...newPlan, billing_cycle: e.target.value })}
                            >
                                <option value="monthly">Monthly</option>
                                <option value="quarterly">Quarterly</option>
                                <option value="yearly">Yearly</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Base Storage (GB)</Form.Label>
                            <Form.Control
                                type="number"
                                value={newPlan.base_storage_gb}
                                onChange={(e) => setNewPlan({ ...newPlan, base_storage_gb: parseFloat(e.target.value) })}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Trial Period (Days)</Form.Label>
                            <Form.Control
                                type="number"
                                value={newPlan.trial_period_days}
                                onChange={(e) => setNewPlan({ ...newPlan, trial_period_days: parseInt(e.target.value) })}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Price ($)</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter price"
                                value={newPlan.current_price}
                                onChange={(e) => setNewPlan({ ...newPlan, current_price: parseFloat(e.target.value) })}
                            />
                        </Form.Group>

                        {/* New PayPal-specific fields */}
                        <Form.Group className="mb-3">
                            <Form.Label>PayPal Plan ID</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter PayPal Plan ID"
                                value={newPlan.paypal_plan_id}
                                onChange={(e) => setNewPlan({ ...newPlan, paypal_plan_id: e.target.value })}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>PayPal Product ID</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter PayPal Product ID"
                                value={newPlan.paypal_product_id}
                                onChange={(e) => setNewPlan({ ...newPlan, paypal_product_id: e.target.value })}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Check
                                type="checkbox"
                                label="Is Trial Only"
                                checked={newPlan.is_trial_only}
                                onChange={(e) => setNewPlan({ ...newPlan, is_trial_only: e.target.checked })}
                            />
                        </Form.Group>

                        <Button
                            variant={selectedPlanId ? 'success' : 'primary'}
                            onClick={selectedPlanId ? handleUpdatePlan : handleCreatePlan}
                            className="me-2"
                        >
                            {selectedPlanId ? 'Update Plan' : 'Create Plan'}
                        </Button>
                        {selectedPlanId && (
                            <Button variant="secondary" onClick={resetForm}>
                                Cancel
                            </Button>
                        )}
                    </Form>
                </Col>
            </Row>

            <h2>Subscription Plans</h2>
            {loading ? (
                <Spinner animation="border" />
            ) : plans.length === 0 ? (
                <p>No subscription plans available.</p>
            ) : (
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            {/* Existing columns remain the same */}
                            <th onClick={() => requestSort('id')}>
                                ID {getSortDirection('id') === 'ascending' ? '▲' : getSortDirection('id') === 'descending' ? '▼' : ''}
                            </th>
                            <th onClick={() => requestSort('name')}>
                                Name {getSortDirection('name') === 'ascending' ? '▲' : getSortDirection('name') === 'descending' ? '▼' : ''}
                            </th>
                            <th onClick={() => requestSort('description')}>
                                Description {getSortDirection('description') === 'ascending' ? '▲' : getSortDirection('description') === 'descending' ? '▼' : ''}
                            </th>
                            <th onClick={() => requestSort('features')}>
                                Features {getSortDirection('features') === 'ascending' ? '▲' : getSortDirection('features') === 'descending' ? '▼' : ''}
                            </th>
                            <th onClick={() => requestSort('billing_cycle')}>
                                Billing Cycle {getSortDirection('billing_cycle') === 'ascending' ? '▲' : getSortDirection('billing_cycle') === 'descending' ? '▼' : ''}
                            </th>
                            <th onClick={() => requestSort('base_storage_gb')}>
                                Base Storage (GB) {getSortDirection('base_storage_gb') === 'ascending' ? '▲' : getSortDirection('base_storage_gb') === 'descending' ? '▼' : ''}
                            </th>
                            <th onClick={() => requestSort('trial_period_days')}>
                                Trial Period (Days) {getSortDirection('trial_period_days') === 'ascending' ? '▲' : getSortDirection('trial_period_days') === 'descending' ? '▼' : ''}
                            </th>
                            <th onClick={() => requestSort('current_price')}>
                                Price ($) {getSortDirection('current_price') === 'ascending' ? '▲' : getSortDirection('current_price') === 'descending' ? '▼' : ''}
                            </th>
                            {/* New PayPal-specific columns */}
                            <th onClick={() => requestSort('paypal_plan_id')}>
                                PayPal Plan ID {getSortDirection('paypal_plan_id') === 'ascending' ? '▲' : getSortDirection('paypal_plan_id') === 'descending' ? '▼' : ''}
                            </th>
                            <th onClick={() => requestSort('paypal_product_id')}>
                                PayPal Product ID {getSortDirection('paypal_product_id') === 'ascending' ? '▲' : getSortDirection('paypal_product_id') === 'descending' ? '▼' : ''}
                            </th>
                            <th onClick={() => requestSort('is_trial_only')}>
                                Is Trial Only {getSortDirection('is_trial_only') === 'ascending' ? '▲' : getSortDirection('is_trial_only') === 'descending' ? '▼' : ''}
                            </th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedPlans.map((plan) => (
                            <tr key={plan.id}>
                                <td>{plan.id}</td>
                                <td>{plan.name ?? ''}</td>
                                <td>{plan.description ?? ''}</td>
                                <td>{plan.features ?? ''}</td>
                                <td>{plan.billing_cycle ?? ''}</td>
                                <td>{plan.base_storage_gb ?? ''}</td>
                                <td>{plan.trial_period_days ?? ''}</td>
                                <td>{plan.current_price ?? ''}</td>
                                <td>{plan.paypal_plan_id ?? ''}</td>
                                <td>{plan.paypal_product_id ?? ''}</td>
                                <td>{plan.is_trial_only ? 'Yes' : 'No'}</td>
                                <td>
                                    <Button
                                        variant="info btn-sm"
                                        onClick={() => setSelectedPlanId(plan.id)}
                                        className="me-2"
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        variant="danger btn-sm mt-1"
                                        onClick={() => handleDeletePlan(plan.id)}
                                    >
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
        </Container>
    );
};

export default SubscriptionPlanManager;