// src/calendar/dateUtils.js

import {
  format,
  parseISO,
  parse,
  isValid,
  addMonths,
  subMonths,
  isSameMonth,
  isToday,
  isSameDay,
} from "date-fns";

export function convertUnixToHumanReadable(unixTimestamp) {
  const date = new Date(unixTimestamp * 1000); // Convert to milliseconds
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  };
  return date.toLocaleDateString(undefined, options);
};

export const formatDate = (date) => {
  // Ensure we're working with a Date object
  const d = new Date(date);
  // Use local date methods to preserve the date as-is
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const getNextMonth = (date) => addMonths(date, 1);

export const getPreviousMonth = (date) => subMonths(date, 1);

export function getDaysInMonth(date) {
  const year = date.getFullYear();
  const month = date.getMonth();
  const firstDay = new Date(year, month, 1);
  const lastDay = new Date(year, month + 1, 0);

  const daysInMonth = [];
  let currentDay = new Date(firstDay);

  // Go back to the last Monday before the 1st of the month
  while (currentDay.getDay() !== 1) {
    currentDay.setDate(currentDay.getDate() - 1);
  }

  // Add days until we reach the first Saturday after the last day of the month
  while (currentDay <= lastDay || currentDay.getDay() !== 0) {
    daysInMonth.push(new Date(currentDay));
    currentDay.setDate(currentDay.getDate() + 1);
  }

  return daysInMonth;
}

export const isCurrentMonth = (day, currentDate) =>
  isSameMonth(day, currentDate);

export const isCurrentDay = (day) => isToday(day);

export const isSameDayAs = (day1, day2) => isSameDay(day1, day2);

export const formatDayNumber = (day) => format(day, "d");

export const formatMonthYear = (date) => {
  try {
    let parsedDate = date;
    if (typeof date === "string") {
      parsedDate = parseISO(date);
    }
    if (!isValid(parsedDate)) {
      throw new Error("Invalid date");
    }
    return format(parsedDate, "MMMM yyyy");
  } catch (error) {
    console.error("Error formatting month and year:", error);
    return "Invalid Date";
  }
};

export const formatDateKey = (date) => format(date, "yyyy/MM/dd");

export const parseDateString = (dateString) => {
  try {
    // First, try parsing as ISO format
    let date = parseISO(dateString);

    // If that doesn't work, try parsing as "yyyy/MM/dd" format
    if (!isValid(date)) {
      date = parse(dateString, "yyyy/MM/dd", new Date());
    }

    // If that still doesn't work, try parsing as "yyyy-MM-dd" format
    if (!isValid(date)) {
      date = parse(dateString, "yyyy-MM-dd", new Date());
    }

    if (!isValid(date)) {
      throw new Error("Invalid date");
    }

    return date;
  } catch (error) {
    console.error("Error parsing date:", error, "Date string:", dateString);
    return null;
  }
};

export const createEmptyEntry = (day) => ({
  type: "doc",
  content: [
    {
      type: "paragraph",
      content: [{ type: "text", text: `Entry for ${formatDate(day)}` }],
    },
  ],
});
