// src/contexts/AuthContext.jsx

import { createContext, useState, useContext, useCallback, useEffect, useMemo } from 'react';
import LoginApiService from '../services/LoginApiService';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const checkAuth = useCallback(async () => {
    setLoading(true);
    try {
      const currentUser = await LoginApiService.getCurrentUser();
      setUser(currentUser);
      setError(null);
    } catch (error) {
      console.log('getCurrentUser error:', error);
      if (error.response && error.response.status === 401) {
        setUser(null);
        // setHasSubscription(false);
      } else {
        console.error('Unexpected error during auth check:', error);
        setError('Failed to authenticate');
      }
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    let isMounted = true;

    const runCheckAuth = async () => {
      if (isMounted) {
        const skipCheckPaths = ['/', '/plans'];
        // Get just the pathname without query parameters
        const currentPath = new URL(window.location.href).pathname;

        if (!skipCheckPaths.includes(currentPath)) {
          await checkAuth();
        } else {
          setLoading(false);
        }
      }
    };


    runCheckAuth();

    return () => {
      isMounted = false;
    };
  }, [checkAuth]);


  const login = useCallback(async (email, password) => {
    setLoading(true);
    try {
      const loginResponse = await LoginApiService.login(email, password);

      if (loginResponse.redirect) {
        // Redirect the user to the pricing page
        window.location.href = loginResponse.redirect;
        return null; // Return null to indicate that a redirect occurred
      }

      setUser(loginResponse.user);
      setError(null);

      return loginResponse;
    } catch (error) {
      console.error('Login error:', error);
      setError('Login failed');
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);


  const logout = useCallback(async () => {
    setLoading(true);
    try {
      await LoginApiService.logout();
      setUser(null);
      setError(null);
    } catch (error) {
      setError('Logout failed');
    } finally {
      setLoading(false);
    }
  }, []);

  // Add this setVerifiedUser function
  const setVerifiedUser = useCallback((userData) => {
    setUser({
      email: userData.email,
      username: userData.username,
      TaskPlexPlanId: userData.TaskPlexPlanId || null,  // Make these optional
      paypalPlanId: userData.paypalPlanId || null,
      paypalProductId: userData.paypalProductId || null,
      planName: userData.planName || null,
      isVerified: true
    });
    setError(null);
  }, []);

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      loading,
      error,
      checkAuth,
      setVerifiedUser, // Include setVerifiedUser in the context value
    }),
    [user, login, logout, loading, error, checkAuth, setVerifiedUser]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
