import { Navigate } from 'react-router-dom';
import React from "react";
import { useAuth } from '../contexts/AuthContext';
import { Container, Tab, Tabs } from "react-bootstrap";
import LoginUserManagement from "../components/userManagement/LoginUserManagement.jsx";
import DataDbUserMgmt from "../components/userManagement/DataDbUserMgmt";
import DiskUsageMonitor from "../components/userManagement/DiskUsageMonitor";
import SubscriptionPlanManager from "../components/userManagement/SubscriptionPlans";
import SubscriptionManagement from "../components/userManagement/SubscriptionMgmt";
import PaymentMonitor from "../components/userManagement/Payments";
import AppFooter from "../components/AppFooter";

const Admin = () => {

  const { user } = useAuth();

  if (!user || !user.is_admin) {
    return <Navigate to="/login" replace />;
  }


  return (
    <div>
      <Container className="mt-4 vh-100">
        <Tabs defaultActiveKey="user-management" id="admin-tabs" className="mb-3">
          <Tab eventKey="user-management" title="Login DB User Mgmt">
            <LoginUserManagement />
          </Tab>
          <Tab eventKey="SubscriptionPlanManager" title="Subscription Plans">
            <SubscriptionPlanManager />
          </Tab>
          <Tab eventKey="SubscriptionManagement" title="Subscriptions">
            <SubscriptionManagement />
          </Tab>
          <Tab eventKey="PaymentMonitor" title="Payments">
            <PaymentMonitor />
          </Tab>
          <Tab eventKey="User Profiles" title="User Profiles">
            <p>User Profiles</p>
          </Tab>
          <Tab eventKey="Data DB user Mgmt" title="Data DB user Mgmt">
            <DataDbUserMgmt />
          </Tab>
          <Tab eventKey="Disk Usage Monitor" title="Disk Usage Monitor">
            <DiskUsageMonitor />
          </Tab>
        </Tabs>
        <AppFooter />
      </Container>
    </div>
  );
};

export default Admin;