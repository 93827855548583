import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Table, Badge, Container, Row, Col, Form, Button, Alert, Card, Modal } from 'react-bootstrap';
import paymentApi from '../../services/paymentApiService';

const PaymentMonitor = () => {
    const [payments, setPayments] = useState([]);
    const [filter, setFilter] = useState('all');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [newPayment, setNewPayment] = useState({
        amount: '',
        payment_method: '',
        subscription_id: '',
        storage_subscription_id: ''
    });
    const { user } = useAuth();

    if (!user.is_admin) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                flexDirection: 'column'
            }}>
                <h1 style={{ fontSize: '4rem', marginBottom: '1rem' }}>403</h1>
                <h2>Forbidden</h2>
                <p>You do not have permission to access this page.</p>
            </div>
        );
    }

    useEffect(() => {
        if (user.is_admin) {
            fetchPayments();
        }
    }, [user]);

    const fetchPayments = async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await paymentApi.getAllPayments();
            setPayments(data);
        } catch (error) {
            console.error('Error fetching payments:', error);
            setError('Failed to fetch payments. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const handleMakePayment = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        try {
            await paymentApi.makePayment(newPayment);
            setShowPaymentModal(false);
            fetchPayments(); // Refresh the payment list
            setNewPayment({ amount: '', payment_method: '', subscription_id: '', storage_subscription_id: '' }); // Reset form
        } catch (error) {
            console.error('Error making payment:', error);
            setError('Failed to make payment. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const getStatusBadge = (status) => {
        switch (status) {
            case 'completed':
                return <Badge bg="success">Completed</Badge>;
            case 'pending':
                return <Badge bg="warning">Pending</Badge>;
            case 'failed':
                return <Badge bg="danger">Failed</Badge>;
            default:
                return <Badge bg="secondary">{status}</Badge>;
        }
    };

    const filteredPayments = payments.filter(payment =>
        filter === 'all' || payment.status === filter
    );

    return (
        <Container>
            <h1 className="my-4">Payment Monitor</h1>
            {error && <Alert variant="danger">{error}</Alert>}
            <Row className="mb-3">
                <Col>
                    <Form.Select
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                        disabled={payments.length === 0}
                    >
                        <option value="all">All Payments</option>
                        <option value="completed">Completed</option>
                        <option value="pending">Pending</option>
                        <option value="failed">Failed</option>
                    </Form.Select>
                </Col>
                <Col>
                    <Button onClick={fetchPayments} disabled={loading}>
                        {loading ? 'Loading...' : 'Refresh'}
                    </Button>
                </Col>
                <Col>
                    <Button onClick={() => setShowPaymentModal(true)}>
                        Make Payment
                    </Button>
                </Col>
            </Row>
            {loading ? (
                <Alert variant="info">Loading payments...</Alert>
            ) : payments.length === 0 ? (
                <Card>
                    <Card.Body>
                        <Card.Title>No Payments Found</Card.Title>
                        <Card.Text>
                            There are currently no payments in the system.
                            This could be because:
                        </Card.Text>
                        <ul>
                            <li>No users have made any payments yet</li>
                            <li>The payment records have been cleared</li>
                            <li>There might be an issue with the payment tracking system</li>
                        </ul>
                        <Card.Text>
                            Try refreshing the page or check back later.
                        </Card.Text>
                    </Card.Body>
                </Card>
            ) : filteredPayments.length === 0 ? (
                <Alert variant="info">No payments match the current filter.</Alert>
            ) : (
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>User ID</th>
                            <th>Subscription ID</th>
                            <th>Storage Subscription ID</th>
                            <th>Amount</th>
                            <th>Date</th>
                            <th>Method</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredPayments.map((payment) => (
                            <tr key={payment.id}>
                                <td>{payment.id}</td>
                                <td>{payment.user_id}</td>
                                <td>{payment.subscription_id}</td>
                                <td>{payment.storage_subscription_id || 'N/A'}</td>
                                <td>${payment.amount}</td>
                                <td>{new Date(payment.payment_date).toLocaleString()}</td>
                                <td>{payment.payment_method}</td>
                                <td>{getStatusBadge(payment.status)}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}

            <Modal show={showPaymentModal} onHide={() => setShowPaymentModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Make Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleMakePayment}>
                        <Form.Group className="mb-3">
                            <Form.Label>Amount</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter amount"
                                value={newPayment.amount}
                                onChange={(e) => setNewPayment({ ...newPayment, amount: e.target.value })}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Payment Method</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter payment method"
                                value={newPayment.payment_method}
                                onChange={(e) => setNewPayment({ ...newPayment, payment_method: e.target.value })}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Subscription ID</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter subscription ID"
                                value={newPayment.subscription_id}
                                onChange={(e) => setNewPayment({ ...newPayment, subscription_id: e.target.value })}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Storage Subscription ID (Optional)</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter storage subscription ID"
                                value={newPayment.storage_subscription_id}
                                onChange={(e) => setNewPayment({ ...newPayment, storage_subscription_id: e.target.value })}
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" disabled={loading}>
                            {loading ? 'Processing...' : 'Submit Payment'}
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default PaymentMonitor;