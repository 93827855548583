import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Card, Form, Button, ListGroup, Alert, Modal, InputGroup } from 'react-bootstrap';
import LoginApiService from '../../services/LoginApiService';

const LoginUserManagement = () => {
  const [users, setUsers] = useState([]);
  const [newUsername, setNewUsername] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const { user } = useAuth();

  // State for search and pagination
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const usersPerPage = 10; // Adjust as needed

  if (!user.is_admin) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column'
      }}>
        <h1 style={{ fontSize: '4rem', marginBottom: '1rem' }}>403</h1>
        <h2>Forbidden</h2>
        <p>You do not have permission to access this page.</p>
      </div>
    );
  }

  useEffect(() => {
    if (user.is_admin) {
      fetchUsers();
    }
  }, [searchTerm, currentPage, user]);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const skip = (currentPage - 1) * usersPerPage;
      const fetchedUsers = await LoginApiService.getAllUsers(searchTerm, skip, usersPerPage);

      if (currentPage === 1) {
        setUsers(fetchedUsers);
      } else {
        setUsers(prevUsers => [...prevUsers, ...fetchedUsers]);
      }

      setHasMore(fetchedUsers.length === usersPerPage);
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Failed to fetch users. Please try again.');
      setUsers([]);
    } finally {
      setLoading(false);
    }
  };

  const validateInput = () => {
    if (newUsername.length < 3) {
      setError('Username must be at least 3 characters long.');
      return false;
    }
    if (!/^\S+@\S+\.\S+$/.test(newEmail)) {
      setError('Please enter a valid email address.');
      return false;
    }
    if (newPassword.length < 8) {
      setError('Password must be at least 8 characters long.');
      return false;
    }
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return false;
    }
    return true;
  };

  const handleCreateUser = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (!validateInput()) return;

    setLoading(true);
    try {
      const newUser = await LoginApiService.createUser({
        username: newUsername,
        email: newEmail,
        password: newPassword,
      });

      setUsers(prevUsers => [newUser, ...prevUsers]);
      setNewUsername('');
      setNewEmail('');
      setNewPassword('');
      setConfirmPassword('');
      setSuccess('User created successfully.');
    } catch (error) {
      console.error('Error creating user:', error);
      setError(error.response?.data?.detail || 'Failed to create user. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteUser = (user) => {
    setUserToDelete(user);
    setShowDeleteModal(true);
  };

  const confirmDeleteUser = async () => {
    setLoading(true);
    setError('');
    setSuccess('');
    try {
      await LoginApiService.deleteUser(userToDelete.id);
      setUsers(prevUsers => prevUsers.filter(user => user.id !== userToDelete.id));
      setSuccess('User deleted successfully.');
    } catch (error) {
      console.error('Error deleting user:', error);
      setError('Failed to delete user. Please try again.');
    } finally {
      setLoading(false);
      setShowDeleteModal(false);
      setUserToDelete(null);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    setUsers([]);
    fetchUsers();
  };

  const handleLoadMore = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  return (
    <div className='container mt-5'>
      <Card className="themed-card mb-3">
        <Card.Header className="themed-card-header">
          <h2 className="h5 mb-0 themed-text">User Management Login Database</h2>
        </Card.Header>
        <Card.Body className='shadow-sm p-3 mb-5 bg-body-tertiary rounded'>
          {error && <Alert variant="danger">{error}</Alert>}
          {success && <Alert variant="success">{success}</Alert>}

          <Form onSubmit={handleCreateUser} autoComplete="off">
            <Form.Group className="mb-3">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                value={newUsername}
                onChange={(e) => setNewUsername(e.target.value)}
                required
                placeholder="Enter new username (min 3 characters)"
                autoComplete="new-username"
                name="new-username"
                minLength={3}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                required
                placeholder="Enter email address"
                autoComplete="new-email"
                name="new-email"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                placeholder="Enter new password (min 8 characters)"
                autoComplete="new-password"
                name="new-password"
                minLength={8}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                placeholder="Confirm new password"
                autoComplete="new-password"
                name="confirm-new-password"
                minLength={8}
              />
            </Form.Group>
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? 'Creating...' : 'Create User'}
            </Button>
          </Form>

          <hr />

          <Form onSubmit={handleSearch} className="mb-5 mt-5">
            <InputGroup>
              <Form.Control
                type="text"
                placeholder="Search users..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Button variant="outline-secondary" type="submit">
                Search
              </Button>
            </InputGroup>
          </Form>

          <h5>User List</h5>
          {loading && users.length === 0 ? (
            <p>Loading users...</p>
          ) : users.length > 0 ? (
            <>
              <ListGroup>
                {users.map((user) => (
                  <ListGroup.Item key={user.id} className="d-flex justify-content-between align-items-center">
                    <div>
                      <strong>ID: {user.id}</strong> - <strong>{user.username}</strong> - {user.email}
                    </div>
                    <Button variant="danger" size="sm" onClick={() => handleDeleteUser(user)} disabled={loading}>
                      Delete
                    </Button>
                  </ListGroup.Item>
                ))}
              </ListGroup>

              {hasMore && (
                <div className="d-flex justify-content-center mt-3">
                  <Button
                    variant="outline-primary"
                    onClick={handleLoadMore}
                    disabled={loading}
                  >
                    {loading ? 'Loading...' : 'Load More'}
                  </Button>
                </div>
              )}
            </>
          ) : (
            <p>No users found.</p>
          )}
        </Card.Body>
      </Card>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the user: <strong>{userToDelete?.username}, ID: {userToDelete?.id}</strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDeleteUser} disabled={loading}>
            {loading ? 'Deleting...' : 'Delete'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LoginUserManagement;